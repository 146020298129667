<template>
  <arcadia-ui :sidebar="true">
    <v-content>
      <v-container fluid>
        <v-layout row justify-start>
          <v-flex>
            <img class="project-avatar-big" :src="projectAvatar" />
          </v-flex>
          <v-flex xs12>
            <a :href="currentGlProject.web_url" target="_blank">
              <h2>{{currentProject.name}}</h2>
              <div class="subheader">( {{currentGlProject.name_with_namespace}} )</div>
            </a>
          </v-flex>
        </v-layout>

        <v-layout row class="fork-info" v-if="currentGlProject.forked_from_project">
          <v-flex>
            <img class="project-avatar" :src="currentGlProject.forked_from_project.avatar_url" />
          </v-flex>
          <v-flex xs12>
            <a :href="currentGlProject.forked_from_project.web_url" target="_blank">
              <h3>{{$t('label.forkedFrom')}}: {{currentGlProject.forked_from_project.name}}</h3>
              <div class="subheader">( {{currentGlProject.forked_from_project.name_with_namespace}} )</div>
            </a>
          </v-flex>
        </v-layout>

        <v-layout>
          <v-flex xs12>
            <h3>{{$t('label.details')}}:</h3>
            <p>{{currentProject.description}}</p>
            <div>{{$t('label.creationDate')}}: {{createdAt}}</div>
            <div>{{allDevices.length}} {{$t('label.devices')}} </div>
            <div>{{allGroups.length}} {{$t('label.groups')}}</div>
            <a v-if="currentProject.metricsUrl"
              :href="currentProject.metricsUrl"
              target="_blank">
              {{$t('label.metricsForProject')}}
            </a>
          </v-flex>
        </v-layout>
      </v-container>
    </v-content>
  </arcadia-ui>
</template>

<script>
import AppStore from '@/stores/AppStore';
import ActionTypes from '@/stores/ActionTypes';
import ArcadiaUi from '@/_components/_core/ArcadiaUi';
import { filepathToComponentName } from '@/utils/utils';

export default {
  name: filepathToComponentName(__filename),
  props: {
    projectName: { type: String, required: true },
  },
  computed: {
    currentProject() {
      return AppStore.state.data.project || {};
    },
    currentGlProject() {
      return AppStore.state.data.gitlabProject || {};
    },
    createdAt() {
      return new Date(this.currentGlProject.created_at).toLocaleDateString();
    },
    allDevices() {
      return AppStore.state.data.projectDevices || [];
    },
    allGroups() {
      return AppStore.state.data.projectGroups || [];
    },
    projectAvatar() {
      return AppStore.state.data.gitlabProject ? AppStore.state.data.gitlabProject.avatar_url
        : (AppStore.state.data.project ? AppStore.state.data.project.avatar : {});
    },
  },
  methods: {
    loadProjectData() {
      AppStore.dispatch(ActionTypes.SET_ACTIVE_PROJECT, this.projectName);
      AppStore.dispatch(ActionTypes.LOAD_GROUPS);
      AppStore.dispatch(ActionTypes.LOAD_DEVICES);
    },
  },
  watch: {
    projectName: {
      handler() { this.loadProjectData(); },
      immediate: true,
    },
  },
  components: { ArcadiaUi },
};
</script>

<style>
.project-details-screen .project-avatar-big {
  width: 60px;
  height: 60px;
  margin-right: 10px;
}
.project-details-screen .project-avatar {
  width: 40px;
  height: 40px;
  margin-right: 10px;
}
.project-details-screen .container {
  max-width: 1000px;
}
.project-details-screen .row {
  margin-bottom: 15px;
}
.project-details-screen .fork-info {
  margin-left: 20px;
}
.project-details-screen a {
  color: inherit;
  text-decoration: none;
}
.project-details-screen a:hover {
  color: blue;
}
.project-details-screen .subheader {
  font-size: 12px;
  font-style: italic;
  color: #666;
}
</style>
