var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-navigation-drawer",
    {
      staticClass: "project-nav",
      attrs: {
        app: "",
        "mini-variant": _vm.isCollapsed,
        width: "240",
        "mini-variant-width": "72",
        clipped: "",
        permanent: "",
      },
    },
    [
      _c("h2", { staticClass: "project-title" }, [
        _vm.projectAvatar
          ? _c("img", {
              staticClass: "icon",
              attrs: { src: _vm.projectAvatar },
            })
          : _vm._e(),
        _vm._v(" "),
        !_vm.isCollapsed
          ? _c("span", { staticClass: "label" }, [
              _vm._v(_vm._s(_vm.projectName)),
            ])
          : _vm._e(),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "project-nav" },
        _vm._l(_vm.projectNavItems, function (navItem) {
          return _c(
            "v-btn",
            {
              key: navItem.route.name,
              class: { active: navItem.route === _vm.activeRoute },
              attrs: {
                href: _vm._f("routeToHref")(navItem.route, {
                  projectName: _vm.projectName,
                }),
                flat: "",
              },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.navigateWithoutReload.apply(null, arguments)
                },
              },
            },
            [
              _c("v-icon", { staticClass: "icon" }, [
                _vm._v(_vm._s(navItem.icon)),
              ]),
              _vm._v(" "),
              !_vm.isCollapsed
                ? _c("span", { staticClass: "label" }, [
                    _vm._v(_vm._s(navItem.label)),
                  ])
                : _vm._e(),
            ],
            1
          )
        }),
        1
      ),
      _vm._v(" "),
      _c(
        "v-btn",
        {
          staticClass: "collapse-toggle-btn",
          attrs: { flat: "" },
          on: {
            click: function ($event) {
              return _vm.confirmArchiveProject()
            },
          },
        },
        [
          _c("v-icon", { staticClass: "icon" }, [
            _vm._v(_vm._s(_vm.archived ? "unarchive" : "archive")),
          ]),
          _vm._v(" "),
          !_vm.isCollapsed
            ? _c("span", { staticClass: "label" }, [
                _vm._v(
                  _vm._s(
                    _vm.archived
                      ? _vm.$t("action.restore")
                      : _vm.$t("action.archive")
                  )
                ),
              ])
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-btn",
        {
          staticClass: "collapse-toggle-btn",
          attrs: { flat: "" },
          on: {
            click: function ($event) {
              _vm.isCollapsed = !_vm.isCollapsed
            },
          },
        },
        [
          _c("v-icon", { staticClass: "icon" }, [
            _vm._v(_vm._s(_vm.isCollapsed ? "chevron_right" : "chevron_left")),
          ]),
          _vm._v(" "),
          !_vm.isCollapsed
            ? _c("span", { staticClass: "label" }, [
                _vm._v(_vm._s(_vm.$t("action.collapseSidebar"))),
              ])
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", "max-width": "290" },
          model: {
            value: _vm.archiveDialog,
            callback: function ($$v) {
              _vm.archiveDialog = $$v
            },
            expression: "archiveDialog",
          },
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", { staticClass: "headline" }, [
                _vm._v(_vm._s(_vm.$t("title.confirm"))),
              ]),
              _vm._v(" "),
              _c("v-card-text", [
                _vm._v(_vm._s(_vm.$t("message.confirmArchive"))),
              ]),
              _vm._v(" "),
              _c(
                "v-card-actions",
                [
                  _c("div", { staticClass: "flex-grow-1" }),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: { text: "" },
                      on: {
                        click: function ($event) {
                          return _vm.archiveProject()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("label.yes")))]
                  ),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: { text: "" },
                      on: {
                        click: function ($event) {
                          return _vm.denyArchiveProject()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("label.no")))]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }