<template>
  <arcadia-ui :sidebar="false" :topbar="false">
    <v-content>
      <v-progress-circular :size="50" indeterminate color="primary" />
    </v-content>
  </arcadia-ui>
</template>

<script>
import ArcadiaUi from '@/_components/_core/ArcadiaUi';
import { getHref, router } from '@/_routing';
import appConfig from '@/appConfig';
import ActionTypes from '@/stores/ActionTypes';
import AppStore from '@/stores/AppStore';
import { filepathToComponentName } from '@/utils/utils';

export default {
  name: filepathToComponentName(__filename),
  mounted() {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('code');
    const returnedState = urlParams.get('state');
    const returnUrl = getHref('/gitlab-oauth', null, { abs: true });
    console.log(`returnurl: ${returnUrl}`);

    if (returnedState) {
      // compare returnedState to saved state
      const savedState = sessionStorage.getItem('gitlabState');
      if (savedState !== returnedState) {
        alert('Invalid state returned, possible CSRF attack.');
        return;
      }

      const codeVerifier = sessionStorage.getItem('gitlabCodeVerifier');
      const body = [
        `code=${code}`,
        `client_id=${appConfig.gitlab.oauthAppID}`,
        `redirect_uri=${returnUrl}`,
        `code_verifier=${codeVerifier}`,
        `grant_type=authorization_code`,
      ].join('&');

      fetch(`${appConfig.gitlab.baseUrl}/oauth/token`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: body,
      })
        .then(response => response.json())
        .then(data => {
          const accessToken = data.access_token;
          AppStore.dispatch(ActionTypes.LOGIN, { gitlabToken: accessToken })
            .then(() => router.push('/'))
            .catch(() => router.push('/login'));
        })
        .catch(error => {
          console.error('Error exchanging code for token:', error);
        });
    } else {
      const codeVerifier = generateRandomString(64);
      const state = generateRandomString(16);

      sha256(codeVerifier).then(hashBuffer => {
        const codeChallenge = base64URLEncode(hashBuffer);
        sessionStorage.setItem('gitlabState', state);
        sessionStorage.setItem('gitlabCodeVerifier', codeVerifier);

        window.location = getOauthUrl({ returnUrl, codeChallenge, state });
      });
    }
  },
  components: { ArcadiaUi },
};

function getOauthUrl({ returnUrl, codeChallenge, state }) {
  return `${appConfig.gitlab.baseUrl}/oauth/authorize?` + [
    `client_id=${appConfig.gitlab.oauthAppID}`,
    `response_type=code`,
    `scope=api+read_user+read_api+read_repository+write_repository+read_registry+write_registry+openid+profile`,
    `code_challenge=${codeChallenge}`,
    `code_challenge_method=S256`,
    `state=${state}`,
    `redirect_uri=${encodeURIComponent(returnUrl)}`,
  ].join('&');
}

function generateRandomString(length) {
  const charset = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let randomString = '';
  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * charset.length);
    randomString += charset.charAt(randomIndex);
  }
  return randomString;
}

function sha256(plainText) {
  const encoder = new TextEncoder();
  const data = encoder.encode(plainText);
  return window.crypto.subtle.digest('SHA-256', data);
}

function base64URLEncode(buffer) {
  const base64 = btoa(String.fromCharCode(...new Uint8Array(buffer)));
  return base64.replace(/=/g, '').replace(/\+/g, '-').replace(/\//g, '_');
}
</script>

<style>
.gitlab-oauth-screen .v-content {
  display: flex;
  height: 100vh;
  align-items: center;
  justify-content: center;
}

.gitlab-oauth-screen .v-content__wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>
