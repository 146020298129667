import ArcadiaBackend from '@/externals/ArcadiaBackend';
import SarbaGitlab from '@/externals/SarbaGitlab';
import MutationTypes from '../MutationTypes';
import ActionTypes from '../ActionTypes';

const IntMutationTypes = {
  START_LOADING: 'START_LOADING',
  STOP_LOADING: 'STOP_LOADING',
  SAVE_PAGE: 'SAVE_PAGE',
};

export default {
  state: {
    // active project & related data
    projectName: null,
    project: null,
    gitlabProject: null,
    projectDescription: null,
    projectBuilds: null,
    projectDevices: null,
    projectGroups: null,
    logs: null,

    // gl project list
    gitlabProjects: {},
    gitlabProjectsCount: 0,

    loadingPromises: {},
    isLoading: false,
  },

  actions: {
    // -- active project & related data ------
    [ActionTypes.SET_ACTIVE_PROJECT](context, payload) {
      const projectName = (isObject(payload) ? payload.name : payload) || null;

      if (context.state.projectName === projectName) {
        if (context.state.loadingPromises.project) {
          return context.state.loadingPromises.project;
        }
        return Promise.resolve(context.state.project);
      }

      context.commit(MutationTypes.SET_PROJECT_NAME, projectName);

      let project, gitlabProject;
      const loadPromise = Promise.resolve()
        .then(() => {
          if (payload == null || typeof payload === 'object') {
            return Promise.resolve(payload || null);
          }
          return ArcadiaBackend.Project.getProjectByName(projectName);
        })
        .then((_project) => {
          project = _project || null;
          if (project == null || project.gitlabProjectId == null) return null;
          return SarbaGitlab.getProjectById(project.gitlabProjectId);
        }).catch((error) => {
          console.log('Cannot find gitlab project');
          console.log(error);
          return null;
        })
        .then((_gitlabProject) => {
          gitlabProject = _gitlabProject || null;
          if (gitlabProject && (project.avatar !== gitlabProject.avatar_url)) {
            project.avatar = gitlabProject.avatar_url;
            ArcadiaBackend.Project.updateProject(project.name, project);
          }

          return context.dispatch(ActionTypes.LOGIN, {
            gitlabToken: context.rootState.auth.gitlabToken,
            projectId: project && project.gitlabProjectId,
          });
        })
        .then(() => {
          if (context.state.loadingPromises.project === loadPromise) {
            context.commit(MutationTypes.SET_PROJECT, { project, gitlabProject });
          }
          return project;
        });

      setupLoading(context, 'project', loadPromise);
      return loadPromise;
    },
    [ActionTypes.LOAD_BUILDS](context, opt={}) {
      if (!opt.refresh) {
        if (context.state.projectBuilds) {
          return Promise.resolve(context.state.projectBuilds);
        } else if (context.state.loadingPromises.projectBuilds) {
          return context.state.loadingPromises.projectBuilds;
        }
      }

      const loadPromise = Promise.resolve(context.state.projectName)
        .then((projectName) => {
          if (projectName == null) return [];
          return ArcadiaBackend.Build.getAllBuilds(projectName);
        })
        .then((builds) => {
          if (context.state.loadingPromises.projectBuilds === loadPromise) {
            context.commit(MutationTypes.SET_PROJECT_BUILDS, builds);
          }
          return builds;
        });

      setupLoading(context, 'projectBuilds', loadPromise);
      return loadPromise;
    },
    [ActionTypes.LOAD_DEVICES](context, opt={}) {
      if (!opt.refresh) {
        if (context.state.projectDevices) {
          return Promise.resolve(context.state.projectDevices);
        } else if (context.state.loadingPromises.projectDevices) {
          return context.state.loadingPromises.projectDevices;
        }
      }

      const loadPromise = Promise.resolve(context.state.projectName)
        .then((projectName) => {
          if (projectName == null) return Promise.resolve([]);
          return ArcadiaBackend.Device.getAllDevices(projectName);
        })
        .then((devices) => {
          if (context.state.loadingPromises.projectDevices === loadPromise) {
            context.commit(MutationTypes.SET_PROJECT_DEVICES, devices);
          }
          return devices;
        });

      setupLoading(context, 'projectDevices', loadPromise);
      return loadPromise;
    },
    [ActionTypes.UNLOAD_DEVICES](context) {
      if (context.state.loadingPromises.projectDevices) {
        context.commit(MutationTypes.STOP_LOADING, {
          id: 'projectDevices',
          loadPromise: context.state.loadingPromises.projectDevices,
        });
      }
      if (context.state.projectDevices) {
        context.commit(MutationTypes.SET_PROJECT_DEVICES, null);
      }
    },
    [ActionTypes.LOAD_GROUPS](context, opt={}) {
      if (!opt.refresh) {
        if (context.state.projectGroups) {
          return Promise.resolve(context.state.projectGroups);
        } else if (context.state.loadingPromises.projectGroups) {
          return context.state.loadingPromises.projectGroups;
        }
      }

      const loadPromise = Promise.resolve(context.state.projectName)
        .then((projectName) => {
          if (projectName == null) return [];
          return ArcadiaBackend.Group.getAllGroups(projectName);
        })
        .then((groups) => {
          if (context.state.loadingPromises.projectGroups === loadPromise) {
            context.commit(MutationTypes.SET_PROJECT_GROUPS, groups);
          }
          return groups;
        });

      setupLoading(context, 'projectGroups', loadPromise);
      return loadPromise;
    },
    [ActionTypes.UNLOAD_GROUPS](context) {
      if (context.state.loadingPromises.projectGroups) {
        context.commit(MutationTypes.STOP_LOADING, {
          id: 'projectGroups',
          loadPromise: context.state.loadingPromises.projectGroups,
        });
      }
      if (context.state.projectGroups) {
        context.commit(MutationTypes.SET_PROJECT_GROUPS, null);
      }
    },

    // -- edits ------
    [ActionTypes.MAKE_BUILD_PERMANENT](context, { projectName, version }) {
      const loadPromise = ArcadiaBackend.Build.makeBuildPermanent(projectName, version)
        .then(() => {
          context.commit(MutationTypes.UPDATE_BUILD,
            { projectName, version, update: { buildType: 'live' } });
        });

      setupLoading(context, 'editBuild' + randomString(), loadPromise);
      return loadPromise;
    },
    [ActionTypes.SAVE_GROUP_DEPLOY_CONFIG](context, { projectName, groupId, deployConfig }) {
      const loadPromise = ArcadiaBackend.Group.setGroupDeployConfig(projectName, groupId, deployConfig)
        .then(() => {
          context.commit(MutationTypes.UPDATE_GROUP,
            { projectName, groupId, update: deployConfig });
        });

      setupLoading(context, 'editGroup' + randomString(), loadPromise);
      return loadPromise;
    },
    [ActionTypes.SAVE_GROUP_CONFIG](context, { projectName, groupId, config }) {
      const loadPromise = ArcadiaBackend.Group.setGroupConfig(projectName, groupId, config)
        .then(() => {
          context.commit(MutationTypes.UPDATE_GROUP,
            { projectName, groupId, update: config });
        });

      setupLoading(context, 'editGroup' + randomString(), loadPromise);
      return loadPromise;
    },
    [ActionTypes.DELETE_GROUP](context, { projectName, groupId }) {
      return ArcadiaBackend.Group.deleteGroup(projectName, groupId);
    },

    [ActionTypes.LOAD_LOGS](context) {
      const loadPromise = Promise.resolve(ArcadiaBackend.Logs.getLogs())
        .then((logs) => context.commit(MutationTypes.SET_LOGS, logs)); // TODO refactor

      setupLoading(context, 'Logs', loadPromise);
      return loadPromise;
    },

    // -- all gitlab projects ------
    [ActionTypes.LOAD_GL_PROJECTS](context, query) {
      const loadPromise = SarbaGitlab.getAllProjects(query)
        .then(({ data, pagination }) => {
          context.commit(IntMutationTypes.SAVE_PAGE, {
            type: 'gitlabProjects',
            page: query.page,
            total: Number(pagination.total),
            data,
          });
          return data;
        });

      setupLoading(context, 'gitlabProjects' + randomString(), loadPromise);
      return loadPromise;
    },
  },

  mutations: {
    // -- active project & related data ------
    [MutationTypes.SET_PROJECT_NAME](state, projectName) {
      state.projectName = projectName;
      state.loadingPromises.project = null;
      state.project = null;
      state.gitlabProject = null;
      state.loadingPromises.projectBuilds = null;
      state.projectBuilds = null;
      state.loadingPromises.projectDevices = null;
      state.projectDevices = null;
      state.loadingPromises.projectGroups = null;
      state.projectGroups = null;
    },
    [MutationTypes.SET_PROJECT](state, { project, gitlabProject }) {
      state.project = project;
      state.gitlabProject = gitlabProject;
    },
    [MutationTypes.SET_PROJECT_BUILDS](state, newBuilds) {
      state.projectBuilds = newBuilds;
    },
    [MutationTypes.SET_LOGS](state, logs) {
      state.logs = logs;
    },
    [MutationTypes.SET_PROJECT_DEVICES](state, newDevices) {
      if (newDevices) { newDevices.forEach(device => { device.groupName = ''; }); } // We need to set the groupName var or else vue won't watch it

      state.projectDevices = newDevices;
    },
    [MutationTypes.SET_PROJECT_GROUPS](state, newGroups) {
      state.projectGroups = newGroups;
    },

    // -- edits ------
    [MutationTypes.UPDATE_BUILD](state, { projectName, version, update }) {
      if (state.projectName !== projectName) return;
      const build = (state.projectBuilds || []).find(b => b.version === version);
      if (build != null) Object.assign(build, update);
    },
    [MutationTypes.UPDATE_GROUP](state, { projectName, groupId, update }) {
      if (state.projectName !== projectName) return;
      const group = (state.projectGroups || []).find(g => g._id === groupId);
      if (group != null) Object.assign(group, update);
    },

    // -- internal ------
    [IntMutationTypes.START_LOADING](state, { id, loadPromise }) {
      state.loadingPromises[id] = loadPromise;
      state.isLoading = true;
    },
    [IntMutationTypes.STOP_LOADING](state, { id, loadPromise }) {
      if (state.loadingPromises[id] === loadPromise) {
        delete state.loadingPromises[id];
      }
      state.isLoading = Object.values(state.loadingPromises).some(v => v);
    },
    [IntMutationTypes.SAVE_PAGE](state, payload) {
      const { type, page, data, total } = payload;
      state[type] = { ...state[type], [page]: data };
      state[`${type}Count`] = total;
    },
  },
};

const setupLoading = (context, id, loadPromise) => {
  context.commit(IntMutationTypes.START_LOADING, { id, loadPromise });
  loadPromise
    .then((v) => {
      context.commit(IntMutationTypes.STOP_LOADING, { id, loadPromise });
      return v;
    })
    .catch((err) => {
      context.commit(IntMutationTypes.STOP_LOADING, { id, loadPromise });
      console.error(err);
    });
};

const randomString = () => {
  const datePart = Date.now().toString(36).slice(-4);
  const rngPart = Math.round(Math.random()*1000000).toString(36).slice(-4);
  return datePart + rngPart;
};

const isObject = v => v != null && typeof v === 'object';
